/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"
import { rem } from "@src/theme"
import ArrowRight from "@components/assets/svg/arrow-right.svg"
import { Themed } from '@theme-ui/mdx'

const CalloutStack = ({ bgColor = "pink", title, blocks, noHeading }) => (
  <div sx={{ bg: bgColor, width: '100%', height: "100%" }}>
    <div sx={{ py: rem(80), px: "5vw" }}>
      <section
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row"],
          height: "100%",
        }}
      >
        {!noHeading && <div
          sx={{
            flex: 1.4,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Themed.h1
            sx={{
              m: 0,
              width: ["85%"],
              maxWidth: "432px",
              mb: [rem(56), null, 0],
            }}
          >
            {title}
          </Themed.h1>
        </div>}

        <div
          sx={{
            flex: 1.8,
          }}
        >
          <div>
            {blocks.map(({ text, link, color, bg }) => (
              <div
                sx={{
                  bg,
                  pl: [rem(29), null, null, rem(64)],
                  py: rem(48),
                }}
              >
                <Link to={link}>
                  <Themed.h2
                    sx={{
                      color,
                      m: 0,
                      fontSize: [rem(24), rem(40)],
                      textDecoration: "underline",
                    }}
                  >
                    {text}
                    <ArrowRight
                      sx={{
                        ml: rem(16),
                        display: ["none", null, null, "inline"],
                      }}
                    />
                  </Themed.h2>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  </div>
)

export default CalloutStack
